@import "../../styles/contants";

.wrapper {
background: white;

  .container {
    align-items: flex-start;
    gap: 2rem;
    flex-wrap: wrap;

    >div /* left right*/ {
      flex: 1;
    }

    .leftSide {
      @media screen and (max-width: 375px),
      (max-width: $md) {
       display: flex;
       justify-content: center;
       border: none;
       max-width: 400px;
       margin-right: 50px;
      }
      display: flex;
      flex-direction: column;
      gap: 2rem ;
    }

    .item {
      display: flex;
      border: 1px solid rgba(0, 0, 0, 0.18);
      border-radius: 20px;
      padding: 1.5rem;
      transition: all 200ms ease-out;

      &:hover {
        cursor: pointer;
        box-shadow: 0px 21px 13px rgba(0, 0, 0, 0.16);
      }

      //icon
      >:nth-child(1) {
        padding: 10px;
        margin-top: 1.5rem;
        margin-right: 1.5rem;
        width: 1rem;
        height: 1rem;
        border-radius: 100%;
      }

      // первый текст
      >:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 1rem;

      >:nth-child(1) {
        font-weight: bold;
        font-size: 1.6rem;
      }

      }

    }

.rightsSlide {
  @media screen and (max-width: $sm){
    text-align: center;

  }
  margin-top: -5.3rem;
  gap: 2px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;

  >span:nth-child(1) {
text-align: center;
margin-right: 40px;
margin-top: 10vh;
padding: 10px;
  }
}

.stats {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 30px;
  }
  
}
  }
}

