@import "./src/styles/contants";

.wrapper {
  position: relative;
  z-index: 99;
  opacity: 1;
  transform: none;
  position: sticky;
  top: 0;
  background-color: white;
  transition: all 300ms ease;

  .container {
    position: relative;
    justify-content: space-between;
    font-width: 500;

    .name {
      font-size: 1.5rem;
      font-weight: 500;
    }

     .menu {
       @media (max-width: $sm),
       (max-width: $md){
         position: absolute;
         list-style: none;
         gap: 2rem;
         top: 3rem;
         right: 0;
         width: 50%;
         flex-direction: column;
         min-width: 15rem;
         background: white;
         display: flex;
         border-radius: 2rem;
         transition: all 300ms ease;
         align-items: flex-start;
         padding: 3rem;
         justify-content: flex-end;
         box-shadow: 0px 4px 4px rgba(0,0,0,0.05);
       }

       text-transform: uppercase;
       gap: 1.5rem;
       cursor: pointer;
       list-style-type: none;
       font-size: 1rem;

       li {
         a {
           color: inherit;
           text-decoration: none;
         }

         &:hover {
           color: $secondary;
         }
       }

       .phone {
         gap: 1.2rem;
         flex-wrap: wrap;

         svg {
           color: $secondary;
           padding: 10px;
           background: white;
           border-radius: 50%;
           box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.09);
           font-size: 10px;
         }
       }
     }
  }
  .menuIcon {
    @media screen and (max-width: $md),
    (max-width: $sm){
      display: block;
    }
    display: none;
  }

}