@import "./src/styles/global";

.wrapper {
  overflow: hidden;
  .container {
    position: relative;
    z-index: 1;

    .upperElements {
      @media screen and (max-width: $sm){
        justify-content: center;
        align-items: center;
        gap: 2rem;
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }

    .lowerElements {
      @media screen and (max-width: $sm),
      (max-width: $md){
        margin-top: 4rem;
        gap: 2rem;
        justify-content: center;
      }

      margin-top: 12rem;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .experience {
        display: flex;
        align-items: center;
        gap: 1rem;
      }

      .secondaryText {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        img {
          margin-bottom: 10px;
          width: 3.5rem;
        }

        span {
          color: gray;
        }
      }
    }
    .person {
      @media screen and (max-width: $sm), (max-width: $md){
        position: relative;
        left: 0;
        background-size: 20rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 100%;
        width: 100%;
        
      }
      background: url("https://raw.githubusercontent.com/ZainRk/Personal-Portfolio-2-starter-pack/master/public/splash.png") no-repeat;
      position: absolute;
      background-position: 50%;
      right: 0;
      left: 0;
      img {
        @media screen and (max-width: $sm){
          width: 23rem;
        }
        display: block;
        margin-left: auto;
        margin-right: auto
      }
    }

    .email {
      @media screen and (max-width: $sm),
      (max-width: $md){
        display: none;
      }
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      margin: 0px 10px;
      color: orange;
    }

  }
}
