@import "../../styles/contants";

.wrapper {
  background: white;
  overflow: hidden;

  .container {
    flex-direction: column;
    gap: 2rem;

    .heading {
      width: 100%;
      justify-content: space-between;
      flex-wrap: wrap;

      >:nth-child(2)
      {
      text-decoration: underline;
        color: orange;
        cursor: pointer;
      }
    }

      .showCase {
        width: 100%;
        gap: 2rem;
        justify-content: space-between;
        flex-wrap: wrap;

        >img{
          @media screen and (max-width: $sm) {
            width: 100%;
          }
          border-radius: 20px;
          max-width: 25rem;
          max-height: 18rem;
          cursor: pointer;
          box-shadow: 0px 21px 13px rgba(0, 0, 0, 0.21);
        }
      }
  }
}